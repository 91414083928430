export interface VariationType {
  key: string;
}

export const withDiscountVaration: VariationType = {
  key: "discounted",
};

export const originalVariation: VariationType = {
  key: "original",
};

export const withVehiclePhotoUploadVariation: VariationType = {
  key: "with-vehicle-photo-upload",
};

export const icoSearchVehicleByNameVariation: VariationType = {
  key: "search-vehicle-by-name",
};
